/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useEffect, useState } from "react";

import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";

import { AUTH_MODAL_VIEW } from "~/analytics/Amplitude/amplitudeConstants";
import { useAnalytics } from "~/analytics/useAnalytics";
import {
  authDeleteUser,
  authSignIn,
  AuthSignInArgs,
  authSignOut,
  authSignUp,
  AuthSignUpArgs,
  getCurrentAuthUser,
  signInWithGoogle,
  signInWithApple,
  handleSocialAuthRedirect,
} from "~/api/authApi";
import { linkAuthUserWithAnonymousUserId } from "~/api/DXAppApi";
import { getNonAuthUserId, removeNonAuthUserId } from "~/api/fetchStudioApi";
import { StudioUser } from "~/api/userApi";
import { AuthModal } from "~/components/auth/AuthModal";
import { AuthForm } from "~/components/auth/authTypes";
import { Result } from "~/util/resultType";

export enum AuthModalInteractionSource {
  HomepageCTA = "HomepageCTA",
  Header = "Header",
  Onboarding = "Onboarding",
}
export type PresentAuthModalAnalyticsData = {
  pageSource?: string;
  interactionSource?: AuthModalInteractionSource;
};

interface AuthState {
  loadingCurrentUser: boolean;
  user: StudioUser | null;
  signIn(args: AuthSignInArgs): Promise<Result<StudioUser>>;
  signUp(args: AuthSignUpArgs): Promise<Result<StudioUser>>;
  signOut(): Promise<Result<null>>;
  deleteUser(): Promise<Result<null>>;
  signInWithGoogle(): Promise<Result<StudioUser>>;
  signInWithApple(): Promise<Result<StudioUser>>;
}

export const AuthContext = React.createContext<AuthState>({
  loadingCurrentUser: false,
  user: null,
  signIn: async () => ({ type: "error", error: new Error("not initialized") }),
  signUp: async () => ({ type: "error", error: new Error("not initialized") }),
  signOut: async () => ({ type: "error", error: new Error("not initialized") }),
  deleteUser: async () => ({
    type: "error",
    error: new Error("not initialized"),
  }),
  signInWithGoogle: async () => ({
    type: "error",
    error: new Error("not initialized"),
  }),
  signInWithApple: async () => ({
    type: "error",
    error: new Error("not initialized"),
  }),
});

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProps) => {
  const [loadingCurrentUser, setLoadingCurrentUser] = React.useState(true);
  const [user, setUser] = React.useState<StudioUser | null>(null);
  const [linkedAnonymousId, setLinkedAnonymousId] = React.useState(false);
  const posthog = usePostHog();

  const router = useRouter();

  // Effect to detect and handle social auth redirects
  // This needs to run before the regular getCurrentUser effect
  React.useEffect(() => {
    // Only run on client side
    if (typeof window === 'undefined') return;

    // Check if this is a social auth redirect from Cognito
    // You can customize this detection logic based on your setup
    const isSocialAuthRedirect = () => {
      // Check for Cognito URL parameters that indicate a redirect
      // Importantly, DON'T handle redirects to the callback page - that's handled separately
      return (
        window.location.href.includes('code=') &&
        window.location.href.includes('state=') &&
        (router.asPath === '/' || router.asPath.startsWith('/?code=')) &&
        !window.location.href.includes('/auth/callback') // Exclude callback page redirects
      );
    };

    const processSocialAuth = async () => {
      console.log('[AuthProvider] Detected social auth redirect on root path, processing login...');
      setLoadingCurrentUser(true);

      try {
        // Check if we had a previous non-social user with the same email
        // Note: This will be determined by our backend after the redirect

        // Use the existing function from authApi.ts to handle the redirect
        // This will link accounts if an email match is found
        const result = await handleSocialAuthRedirect();

        if (result.type === 'success') {
          console.log('[AuthProvider] Social auth successful, setting user state...', result.value);
          setUser(result.value);

          // Identify the user in analytics
          posthog?.identify(result.value.userId, {
            email: result.value.email,
            firstName: result.value.firstName,
            lastName: result.value.lastName,
            username: result.value.username,
            signUpDate: result.value.signUpDate,
            miniBio: result.value.miniBio,
            membershipStatus: result.value.membershipStatus,
            isSocialSignIn: true
          });

          // Check if we should redirect back to onboarding
          if (result.metadata?.returnToOnboarding && result.metadata.returnPath) {
            console.log('[AuthProvider] Redirecting back to onboarding flow:', result.metadata.returnPath);
            router.push(result.metadata.returnPath);
            return;
          }

          // Optional: Clean up URL parameters if we're not redirecting elsewhere
          if (window.history && window.history.replaceState) {
            window.history.replaceState({}, document.title, '/');
          }
        } else {
          console.error('[AuthProvider] Social auth redirect processing failed:', result.error);
          setUser(null);

          // Display error to user if appropriate
          if (result.message) {
            // You could use a toast or alert mechanism here
            console.error('[AuthProvider] Login error:', result.message);
          }
        }
      } catch (error) {
        console.error('[AuthProvider] Unexpected error during social auth processing:', error);
        setUser(null);
      } finally {
        setLoadingCurrentUser(false);
      }
    };

    // If this is a social auth redirect, process it
    if (isSocialAuthRedirect()) {
      processSocialAuth();
    }
  }, [router.asPath]);

  React.useEffect(() => {
    // Skip this effect if we're handling a social auth redirect
    // as that would be handled by the effect above
    if (typeof window !== 'undefined' &&
      window.location.href.includes('code=') &&
      window.location.href.includes('state=')) {
      return;
    }

    let active = true;
    const getCurrentUserOnMount = async () => {
      const result = await getCurrentAuthUser();
      if (result.type === "success" && active) {
        const user = result.value;
        setUser(user);

        console.log("GOT USER user.currentSessionSlug: ", user.currentSessionSlug);

        if (user.currentSessionSlug && router.asPath == "/") {
          router.push(`/school/music`);
        }

        posthog?.identify(user.userId, {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          signUpDate: user.signUpDate,
          miniBio: user.miniBio,
          membershipStatus: user.membershipStatus,
        });

        setLoadingCurrentUser(false);
      } else {
        if (active) {
          setUser(null);
          setLoadingCurrentUser(false);
        }
      }
    };
    getCurrentUserOnMount();
    return () => {
      active = false;
    };
  }, [setUser, setLoadingCurrentUser]);

  const signIn = useCallback(
    async (args: AuthSignInArgs) => {
      console.log("signing in with args: ", args);
      const result = await authSignIn(args);
      console.log("this is the signing result: ", result);
      if (result.type === "success") {
        const user = result.value;
        setUser(user);

        console.log("GOT USER user.currentSessionSlug: ", user.currentSessionSlug, user);
        console.log("GOT router.asPath: ", router.asPath);

        if (user.currentSessionSlug && !router.asPath.startsWith("/school/")) {
          router.push(`/school/music`);
        }

        posthog?.identify(user.userId, {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          signUpDate: user.signUpDate,
          miniBio: user.miniBio,
          membershipStatus: user.membershipStatus,
        });
      } else {
        setUser(null);
      }
      return result;
    },
    [setUser],
  );

  const signOut = useCallback(async () => {
    try {
      console.log("SIGNING OUT from AuthProvider");
      // First, set user to null in our local state to update UI immediately
      setUser(null);

      // Remove the non-auth user ID when signing out
      removeNonAuthUserId();

      // Then attempt the actual sign-out
      const result = await authSignOut();

      if (result.type === "success") {
        console.log("Sign out successful");
        // The redirect will be handled by authSignOut for social users
        // For regular users, redirect to home
        if (!window.location.href.includes('code=') && !window.location.href.includes('state=')) {
          await router.push("/");
        }
      } else {
        console.error("Error during sign out:", result.error);
      }

      return result;
    } catch (error) {
      console.error("Error in signOut function:", error);
      return {
        type: "error" as const,
        error: error instanceof Error ? error : new Error("Unknown error during sign-out")
      };
    }
  }, [setUser, router]);

  const signUp = useCallback(
    async (args: AuthSignUpArgs) => {
      const result = await authSignUp(args);
      if (result.type === "success") {
        setUser(result.value);
      } else {
        setUser(null);
      }
      return result;
    },
    [setUser],
  );

  const deleteUser = useCallback(async () => {
    const result = await authDeleteUser();
    if (result.type === "success") {
      setUser(null);
    }
    return result;
  }, [setUser]);

  // Effect to link anonymous user ID with authenticated user when authenticated
  useEffect(() => {
    const linkAnonymousId = async () => {
      if (user && !loadingCurrentUser && !linkedAnonymousId) {
        try {
          const anonymousUserId = getNonAuthUserId();
          if (anonymousUserId) {
            console.log("[AuthProvider] Attempting to link anonymous user ID:", anonymousUserId);
            console.log("[AuthProvider] Current authenticated user:", user.userId);

            const response = await linkAuthUserWithAnonymousUserId(anonymousUserId);

            // If linking was successful and data was linked
            if (response && response.success && response.dataLinked) {
              console.log("[AuthProvider] Successfully linked anonymous user ID with meaningful data");
              setLinkedAnonymousId(true);
            }
            // If linking was successful but no meaningful data was found
            else if (response && response.success && !response.dataLinked) {
              console.log("[AuthProvider] No meaningful data found for anonymous user ID, no linking needed");
              setLinkedAnonymousId(true); // Still mark as processed
            }
            // If linking failed
            else {
              console.log("[AuthProvider] Failed to link anonymous user ID:", response);
              console.log("[AuthProvider] This error is non-critical and won't affect your login flow");
              // We still mark as processed to prevent infinite retries
              setLinkedAnonymousId(true);
            }
          } else {
            console.log("[AuthProvider] No anonymous user ID found to link");
            setLinkedAnonymousId(true); // Mark as processed since there's nothing to link
          }
        } catch (err) {
          console.error("[AuthProvider] Error linking anonymous user ID:", err);
          console.log("[AuthProvider] This error is non-critical and won't affect your login flow");
          // Mark as processed even on error to prevent infinite retries
          setLinkedAnonymousId(true);
        }
      }
    };

    linkAnonymousId();
  }, [user, loadingCurrentUser, linkedAnonymousId]);

  // Handle Google Sign In
  const handleSignInWithGoogle = useCallback(async () => {
    console.log("handleSignInWithGoogle in AuthProvider");
    const result = await signInWithGoogle();
    if (result.type === "success") {
      const user = result.value;
      setUser(user);

      if (user.currentSessionSlug && !router.asPath.startsWith("/school/")) {
        router.push(`/school/music`);
      }

      posthog?.identify(user.userId, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        signUpDate: user.signUpDate,
        miniBio: user.miniBio,
        membershipStatus: user.membershipStatus,
      });

      // Link any anonymous user ID if available
      try {
        const anonymousUserId = getNonAuthUserId();
        if (anonymousUserId) {
          await linkAuthUserWithAnonymousUserId(anonymousUserId);
        }
      } catch (err) {
        console.error("Error linking anonymous user ID after Google sign-in:", err);
      }
    }
    return result;
  }, [router, posthog]);

  // Handle Apple Sign In
  const handleSignInWithApple = useCallback(async () => {
    const result = await signInWithApple();
    if (result.type === "success") {
      const user = result.value;
      setUser(user);

      if (user.currentSessionSlug && !router.asPath.startsWith("/school/")) {
        router.push(`/school/music`);
      }

      posthog?.identify(user.userId, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        signUpDate: user.signUpDate,
        miniBio: user.miniBio,
        membershipStatus: user.membershipStatus,
      });

      // Link any anonymous user ID if available
      try {
        const anonymousUserId = getNonAuthUserId();
        if (anonymousUserId) {
          await linkAuthUserWithAnonymousUserId(anonymousUserId);
        }
      } catch (err) {
        console.error("Error linking anonymous user ID after Apple sign-in:", err);
      }
    }
    return result;
  }, [router, posthog]);

  // useMemo to not recreate object on each render
  const contextValue = useMemo(
    () => ({
      user,
      loadingCurrentUser,
      signIn,
      signUp,
      signOut,
      deleteUser,
      signInWithGoogle: handleSignInWithGoogle,
      signInWithApple: handleSignInWithApple,
    }),
    [user, loadingCurrentUser, signIn, signUp, signOut, deleteUser, handleSignInWithGoogle, handleSignInWithApple],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
