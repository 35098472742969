/**
 * Utility functions for working with browser storage with fallbacks
 * Handles cases where localStorage might not be available:
 * - Private/incognito mode
 * - User has disabled storage
 * - Older browsers
 */

/**
 * Tests if localStorage is available
 */
export const isLocalStorageAvailable = (): boolean => {
    try {
        const testKey = '__storage_test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 * Tests if sessionStorage is available
 */
export const isSessionStorageAvailable = (): boolean => {
    try {
        const testKey = '__storage_test__';
        sessionStorage.setItem(testKey, testKey);
        sessionStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

// Fallback in-memory storage
const memoryStorage: Record<string, string> = {};

/**
 * Saves state to the best available storage option
 * Falls back gracefully between:
 * 1. localStorage (persistent)
 * 2. sessionStorage (session-based)
 * 3. In-memory JavaScript object (page refresh will lose data)
 */
export const saveStateToStorage = (key: string, data: any): void => {
    try {
        const serializedData = JSON.stringify(data);

        // Try localStorage first
        if (isLocalStorageAvailable()) {
            localStorage.setItem(key, serializedData);
            return;
        }

        // Try sessionStorage next
        if (isSessionStorageAvailable()) {
            sessionStorage.setItem(key, serializedData);
            console.log('localStorage not available, using sessionStorage as fallback');
            return;
        }

        // Last resort: in-memory storage
        memoryStorage[key] = serializedData;
        console.log('Browser storage not available, using in-memory storage as fallback');
    } catch (error) {
        console.error('Error saving state to storage:', error);
    }
};

/**
 * Gets state from the best available storage option
 * Checks all storage options in order
 */
export const getStateFromStorage = <T>(key: string): T | null => {
    try {
        let serializedData: string | null = null;

        // Try localStorage first
        if (isLocalStorageAvailable()) {
            serializedData = localStorage.getItem(key);
            if (serializedData) {
                return JSON.parse(serializedData);
            }
        }

        // Try sessionStorage next
        if (isSessionStorageAvailable()) {
            serializedData = sessionStorage.getItem(key);
            if (serializedData) {
                return JSON.parse(serializedData);
            }
        }

        // Last resort: in-memory storage
        if (memoryStorage[key]) {
            return JSON.parse(memoryStorage[key]);
        }

        return null;
    } catch (error) {
        console.error('Error retrieving state from storage:', error);
        return null;
    }
};

/**
 * Removes state from all storage options
 */
export const removeStateFromStorage = (key: string): void => {
    try {
        if (isLocalStorageAvailable()) {
            localStorage.removeItem(key);
        }

        if (isSessionStorageAvailable()) {
            sessionStorage.removeItem(key);
        }

        delete memoryStorage[key];
    } catch (error) {
        console.error('Error removing state from storage:', error);
    }
}; 