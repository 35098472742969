import { Auth } from "aws-amplify";

import * as Sentry from "@sentry/nextjs";
import { v4 as uuidv4 } from 'uuid';

export enum ApiStatus {
  DEFAULT = "default",
  PENDING = "pending",
  ERROR = "error",
  SUCCESS = "success",
  FORBIDDEN = "forbidden",
  NOT_FOUND = "not found",
}

export interface ApiResult {
  status: ApiStatus;
  message: string;
}

export const API_ENDPOINT = process.env.NEXT_PUBLIC_ENV_API;
export const DX_API_ENDPOINT = process.env.NEXT_PUBLIC_DX_ENV_API;
const CORS_HEADER = "MONTHLY-HEADER";
const USER_TIMEZONE_HEADER = "User-Browser-Timezone";

// Non-authenticated user ID management
const NON_AUTH_USER_ID_KEY = 'dx_non_auth_user_id';

export const getNonAuthUserId = (): string => {
  // Try to get existing ID from localStorage
  if (typeof window !== 'undefined') {
    const existingId = localStorage.getItem(NON_AUTH_USER_ID_KEY);
    if (existingId) {
      return existingId;
    }

    // Generate new ID if none exists
    const newId = `anon_${uuidv4()}`;
    localStorage.setItem(NON_AUTH_USER_ID_KEY, newId);
    return newId;
  }

  // Fallback for SSR
  return '';
};

export const removeNonAuthUserId = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(NON_AUTH_USER_ID_KEY);
  }
};

// New function to get the appropriate user ID based on auth status
export const getUserId = (authUser: any | null): string => {
  if (authUser && authUser.userId) {
    return authUser.userId;
  }
  return getNonAuthUserId();
};

export const fetchApiPublic = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
}) => await fetchApi({ ...args, authRequired: false });

export const fetchApiAuthenticated = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
}) => await fetchApi({ ...args, authRequired: true });

export const fetchApi = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
  authRequired?: boolean;
}) => {
  const { path, method, body, isJsonPayload = false, authRequired = false } = args;
  const response = await fetch(`${API_ENDPOINT}${path}`, {
    method,
    mode: "cors",
    headers: await generateDefaultHeaders({
      isJsonPayload,
      authRequired,
    }),
    body,
  });
  console.log("response", response);
  const result = await response.json();
  return result;
};

const generateDefaultHeaders = async (args: {
  authRequired?: boolean;
  isJsonPayload?: boolean;
}) => {
  const { authRequired = false, isJsonPayload = false } = args;
  const headers: HeadersInit = {
    accept: "application/json",
    "Access-Control-Request-Headers": CORS_HEADER,
    ...(isJsonPayload && { "Content-Type": "application/json" }),
  };
  if (authRequired) {
    const authorizationToken = await getJWTToken();
    if (authorizationToken && authorizationToken.length > 0) {
      headers["Authorization"] = "Bearer " + authorizationToken;
    } else {
      //throw new Error("No auth token");
    }
  }

  headers[USER_TIMEZONE_HEADER] = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // console.log("headers", headers);

  return headers;
};

export const getJWTToken = async () => {
  try {
    const userSession = await Auth.currentSession();
    if (userSession) {
      return userSession.getAccessToken().getJwtToken();
    } else {
      //throw new Error("No user session");
    }
  } catch (error: any) {
    console.log("Error - no auth token found");
    //localStorage.clear();
    Sentry.captureException(error);
    // window.location.href = `${window.location.protocol}//${window.location.host}/login`;
    return;
  }
};

// New DX API functions
export const fetchDxApiPublic = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
  nonAuthUserId?: string;
  authUser?: any;
}) => await fetchDxApi({ ...args, authRequired: false });

export const fetchDxApiAuthenticated = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
}) => await fetchDxApi({ ...args, authRequired: true });

export const fetchDxApi = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
  authRequired?: boolean;
  nonAuthUserId?: string;
  authUser?: any;
}) => {
  const { path, method, body, isJsonPayload = false, authRequired = false, nonAuthUserId, authUser } = args;

  // Prepare headers with or without auth
  const headers = await generateDefaultHeaders({
    isJsonPayload,
    authRequired,
  });

  // Add user ID to headers - prefer auth user ID if available
  const userId = getUserId(authUser);
  if (!authRequired) {
    headers['X-Non-Auth-User-Id'] = userId;
  }

  const response = await fetch(`${DX_API_ENDPOINT}${path}`, {
    method,
    mode: "cors",
    headers,
    body,
  });
  console.log("response", response);
  const result = await response.json();
  return result;
};
