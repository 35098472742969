import { Auth } from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";

import { ApiStatus, fetchApiAuthenticated, fetchDxApiAuthenticated, fetchDxApi } from "~/api/fetchStudioApi";
import { fetchServerUp } from "~/api/health";
import { fetchAuthenticatedUser, StudioUser } from "~/api/userApi";
import { getUserFacingCognitoError } from "~/util/cognitoUtils";
import {
  CREATE_ACCOUNT_FAILURE_MESSAGE,
  GENERIC_ERROR_MESSAGE,
  NETWORK_FAILURE_ERROR_MESSAGE,
} from "~/util/commonErrors";
import { Result } from "~/util/resultType";
import { getStateFromStorage, removeStateFromStorage } from '~/util/storageUtils';

export const getCurrentAuthUser = async (): Promise<Result<StudioUser>> => {
  try {
    await Auth.currentAuthenticatedUser();
    const getUserResponse = await fetchAuthenticatedUser();
    if (getUserResponse.type === "success") {
      const user = getUserResponse.value;
      return { type: "success", value: user };
    } else {
      return {
        type: "error",
        error: getUserResponse.error,
        message: getUserResponse.message || GENERIC_ERROR_MESSAGE,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    return { type: "error", error: error as Error };
  }
};

export type AuthSignInArgs = {
  email: string;
  password: string;
};
export const authSignIn = async ({
  email,
  password,
}: AuthSignInArgs): Promise<Result<StudioUser>> => {
  const lowercaseEmail = email.toLowerCase();
  try {
    await Auth.signIn(lowercaseEmail, password);
    const getUserResponse = await fetchAuthenticatedUser();
    if (getUserResponse.type === "success") {
      const user = getUserResponse.value;
      return { type: "success", value: user };
    } else {
      const errorMessage = getUserFacingCognitoError(getUserResponse.error.message, "login");
      return {
        type: "error",
        error: getUserResponse.error,
        message: errorMessage,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    let message;
    if (error instanceof Error) {
      message = getUserFacingCognitoError(error.message, "login");
    } else {
      message =
        "There was an error signing in to your account. Please try again or contact us at support@studio.com";
    }
    return {
      type: "error",
      error: error as Error,
      message,
    };
  }
};

export const authSignOut = async (): Promise<Result<null>> => {
  try {
    // First clear the local session
    await Auth.signOut();
    return { type: "success", value: null };
  } catch (error) {
    Sentry.captureException(error);
    console.error("Sign out error:", error);
    return { type: "error", error: error as Error };
  }
};

export type AuthSignUpArgs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};
export const authSignUp = async ({
  firstName,
  lastName,
  email,
  password,
}: AuthSignUpArgs): Promise<Result<StudioUser>> => {
  try {
    const isServerUp = await fetchServerUp();
    if (!isServerUp) {
      return {
        type: "error",
        error: new Error(NETWORK_FAILURE_ERROR_MESSAGE),
        message: NETWORK_FAILURE_ERROR_MESSAGE,
      };
    }

    const lowercaseEmail = email.toLowerCase();

    const uuidUsername = uuidv4(); // random username, since 'email' is a valid login alias

    // use Cognito to signUp(), and then immediately call signIn() to get local tokens. stupid, I know.
    await Auth.signUp({
      username: uuidUsername,
      password,
      attributes: { email: lowercaseEmail },
    });

    console.log("Signed up and now Signing in with Cognito ", uuidUsername, lowercaseEmail);
    const cognitoUser = await Auth.signIn(uuidUsername, password);

    const formData = new FormData();
    formData.append("userId", uuidUsername);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", lowercaseEmail);
    const createStudioUserRes = await fetchApiAuthenticated({
      path: "/createUser",
      method: "POST",
      body: formData,
    });

    if (createStudioUserRes.status !== ApiStatus.SUCCESS) {
      // If backend creation failed, delete the Cognito entry so the user can try again
      const createStudioUserError = new Error(
        `Backend createUser for ${lowercaseEmail} failed, deleting cognito user`,
      );
      console.warn(createStudioUserError);
      Sentry.captureException(createStudioUserError);
      cognitoUser.deleteUser((error?: Error) => {
        if (error) {
          throw error;
        }
      });
      // Set email_verified to True for existing cognito user
      // This prevents the user with the email from being locked out
      // of logging in if they try to create a new account
      const formData = new FormData();
      formData.append("email", lowercaseEmail);
      await fetchApiAuthenticated({
        path: "/confirmEmail",
        method: "POST",
        body: formData,
      });

      return {
        type: "error",
        error: new Error(createStudioUserRes.status),
        message: createStudioUserRes.message || CREATE_ACCOUNT_FAILURE_MESSAGE,
      };
    }

    const getUserResult = await fetchAuthenticatedUser();
    if (getUserResult.type === "success") {
      return { type: "success", value: getUserResult.value };
    } else {
      Sentry.captureException(getUserResult.error);
      return { type: "error", error: getUserResult.error };
    }
  } catch (error) {
    console.log("create user error", error);
    Sentry.captureException(error);
    return {
      type: "error",
      error: error as Error,
      message: error instanceof Error ? error.message : "",
    };
  }
};

export const authDeleteUser = async (): Promise<Result<null>> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await cognitoUser.deleteUser;
    return { type: "success", value: null };
  } catch (error) {
    Sentry.captureException(error);
    return { type: "error", error: error as Error };
  }
};

export const authSendResetPasswordEmail = async (email: string): Promise<Result<null>> => {
  try {
    await Auth.forgotPassword(email);
    return { type: "success", value: null };
  } catch (error) {
    Sentry.captureException(error);
    return { type: "error", error: error as Error };
  }
};

// Social authentication with Google
export const signInWithGoogle = async (): Promise<Result<StudioUser>> => {
  try {
    console.log("Starting Google sign-in process");

    // Clear any previous sign-in data
    await Auth.signOut();

    // Use the simple federatedSignIn method
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    });

    // This code won't be reached in normal flow due to redirect
    // The browser will be redirected to Google's authentication page
    // After successful authentication, it will redirect back to the configured callback URL
    // No need to return anything here as the redirect will happen

    // In case the redirect doesn't happen (which would be unusual), provide a fallback
    // This is technically unreachable code in normal operation
    console.log("Warning: Google authentication redirect did not occur");
    return {
      type: "error",
      error: new Error("Google authentication redirect failed"),
      message: "Failed to redirect to Google login. Please try again or use a different login method."
    };
  } catch (error) {
    Sentry.captureException(error);
    console.error("Google sign-in error:", error);
    return {
      type: "error",
      error: error as Error,
      message: "Error signing in with Google. Please try again."
    };
  }
};

// Social authentication with Apple
export const signInWithApple = async (): Promise<Result<StudioUser>> => {
  try {
    console.log("Starting Apple sign-in process");

    // Clear any previous sign-in data with a more thorough cleanup
    try {
      // Sign out globally to clear all auth state
      await Auth.signOut({ global: true });

      // Force a reload of the auth state to clear any cached data
      try {
        await Auth.currentAuthenticatedUser().catch(() => {
          // Ignore the error - we expect this to fail since we just signed out
        });
      } catch (e) {
        // Ignore any errors here
      }

      // Clear any stored state from our application
      removeStateFromStorage('onboardingAuthState');

      // Clear any other potential stored state
      if (typeof window !== 'undefined') {
        localStorage.clear();
        sessionStorage.clear();
      }
    } catch (cleanupError) {
      console.log("Cleanup error (non-critical):", cleanupError);
    }

    // Use the simple federatedSignIn method
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple
    });

    // This code won't be reached in normal flow due to redirect
    // The browser will be redirected to Apple's authentication page
    // After successful authentication, it will redirect back to the configured callback URL
    // No need to return anything here as the redirect will happen

    // In case the redirect doesn't happen (which would be unusual), provide a fallback
    // This is technically unreachable code in normal operation
    console.log("Warning: Apple authentication redirect did not occur");
    return {
      type: "error",
      error: new Error("Apple authentication redirect failed"),
      message: "Failed to redirect to Apple login. Please try again or use a different login method."
    };
  } catch (error) {
    Sentry.captureException(error);
    console.error("Apple sign-in error:", error);
    return {
      type: "error",
      error: error as Error,
      message: "Error signing in with Apple. Please try again."
    };
  }
};


// This function should be called when the app loads after a redirect from Cognito
export const handleSocialAuthRedirect = async (
  storefrontSlug?: string,
  appSlug?: string
): Promise<Result<StudioUser>> => {
  try {
    console.log('Handling social auth redirect');

    // First, try to get the current session
    let currentUser;
    try {
      currentUser = await Auth.currentAuthenticatedUser();
      console.log("Successfully got current authenticated user");
    } catch (authError) {
      console.error("Error getting current authenticated user:", authError);

      // Try to get the session directly
      try {
        const session = await Auth.currentSession();
        console.log("Got current session:", session);
        currentUser = await Auth.currentAuthenticatedUser();
      } catch (sessionError) {
        console.error("Error getting current session:", sessionError);

        // If we have a code in the URL, try to handle the OAuth flow
        // if (window.location.href.includes('code=')) {
        //   try {
        //     // Try to handle the OAuth flow manually
        //     const urlParams = new URLSearchParams(window.location.search);
        //     const code = urlParams.get('code');
        //     if (code) {
        //       console.log("Found code in URL, attempting to handle OAuth flow");
        //       // Let Amplify handle the OAuth flow
        //       await Auth.federatedSignIn();
        //       currentUser = await Auth.currentAuthenticatedUser();
        //     }
        //   } catch (oauthError) {
        //     console.error("Error handling OAuth flow:", oauthError);
        //   }
        // }
      }
    }

    if (!currentUser) {
      console.error("Could not get authenticated user after all attempts");
      return {
        type: "error",
        error: new Error("The user is not authenticated"),
        message: "Failed to complete authentication. Please try signing in again."
      };
    }

    console.log("Current authenticated user from Cognito:", currentUser);
    console.log("Current user structure:", Object.keys(currentUser));

    // DETAILED LOGGING: Log all potentially useful parts of the currentUser object
    console.log("Username:", currentUser.username);
    console.log("User attributes:", currentUser.attributes);

    if (currentUser.signInUserSession) {
      console.log("Sign-in session structure:", Object.keys(currentUser.signInUserSession));

      // JWT tokens often contain useful information
      if (currentUser.signInUserSession.idToken) {
        console.log("ID Token structure:", Object.keys(currentUser.signInUserSession.idToken));
        console.log("ID Token JWT:", currentUser.signInUserSession.idToken.jwtToken);
        if (currentUser.signInUserSession.idToken.payload) {
          console.log("ID Token payload:", currentUser.signInUserSession.idToken.payload);
          console.log("ID Token payload keys:", Object.keys(currentUser.signInUserSession.idToken.payload));

          // Email might be in the token claims
          console.log("ID Token email:", currentUser.signInUserSession.idToken.payload.email);
          console.log("ID Token email_verified:", currentUser.signInUserSession.idToken.payload.email_verified);
        }
      }

      // Access token might also have info
      if (currentUser.signInUserSession.accessToken) {
        console.log("Access Token structure:", Object.keys(currentUser.signInUserSession.accessToken));
        console.log("Access Token payload:", currentUser.signInUserSession.accessToken.payload);
      }
    }

    // For Google/Social sign-in, the attributes might be in a different location
    // Try multiple paths to get user attributes
    let attributes = currentUser.attributes || {};

    console.log("Initial attributes:", attributes);

    // If attributes are empty, try getting them from the user object directly
    if (Object.keys(attributes).length === 0 || !attributes.email) {
      console.log("No email found in standard location, checking alternatives...");

      // Check if we have id_token with user info (common with federation)
      if (currentUser.signInUserSession?.idToken?.payload) {
        const payload = currentUser.signInUserSession.idToken.payload;
        console.log("Found id token payload:", payload);

        // For Google specifically, the email is often in the payload
        if (payload.email) {
          console.log("Found email in payload:", payload.email);
          attributes.email = payload.email;
        }

        // Check for other attributes we might need
        if (payload.given_name && !attributes.given_name) {
          attributes.given_name = payload.given_name;
          console.log("Found given_name in payload:", payload.given_name);
        }

        if (payload.family_name && !attributes.family_name) {
          attributes.family_name = payload.family_name;
          console.log("Found family_name in payload:", payload.family_name);
        }

        if (payload.name && !attributes.name) {
          attributes.name = payload.name;
          console.log("Found name in payload:", payload.name);
        }

        // Check for 'cognito:username' or similar fields
        for (const key in payload) {
          if (key.includes('email') || key.includes('mail')) {
            console.log(`Found potential email field in payload: ${key} = ${payload[key]}`);
          }
          if (key.includes('name') || key.includes('Name')) {
            console.log(`Found potential name field in payload: ${key} = ${payload[key]}`);
          }
        }
      }

      // For Google federation, check identities array
      if (currentUser.signInUserSession?.idToken?.payload) {
        const identities = currentUser.signInUserSession.idToken.payload.identities;
        if (Array.isArray(identities) && identities.length > 0) {
          console.log("Found identities array:", identities);

          for (const identity of identities) {
            console.log("Processing identity:", identity);
            console.log("Identity structure:", Object.keys(identity));

            // Handle both Google and Apple identities
            if (identity.providerType === 'Google' || identity.providerName === 'Google') {
              console.log("Found Google identity with keys:", Object.keys(identity));

              if (identity.email) {
                console.log("Found email in Google identity:", identity.email);
                attributes.email = identity.email;
              }

              // Look for any key that might contain email
              for (const key in identity) {
                if (key.includes('email') || key.includes('mail')) {
                  console.log(`Found potential email field in identity: ${key} = ${identity[key]}`);
                }
              }
            } else if (identity.providerType === 'Apple' || identity.providerName === 'Apple') {
              console.log("Found Apple identity with keys:", Object.keys(identity));

              // Apple might provide email in different fields
              if (identity.email) {
                console.log("Found email in Apple identity:", identity.email);
                attributes.email = identity.email;
              }

              // Apple sometimes provides email in the userIdentifier field
              if (identity.userIdentifier && identity.userIdentifier.includes('@')) {
                console.log("Found email in Apple userIdentifier:", identity.userIdentifier);
                attributes.email = identity.userIdentifier;
              }

              // Look for any key that might contain email
              for (const key in identity) {
                if (key.includes('email') || key.includes('mail')) {
                  console.log(`Found potential email field in Apple identity: ${key} = ${identity[key]}`);
                }
              }
            }
          }
        }
      }

      // Check if email might be nested elsewhere in the token
      if (currentUser.signInUserSession?.idToken?.payload) {
        const checkNestedObject = (obj: any, path = '') => {
          for (const key in obj) {
            if (obj[key] && typeof obj[key] === 'object') {
              checkNestedObject(obj[key], `${path}.${key}`);
            } else if (
              (key.includes('email') || key.includes('mail')) &&
              typeof obj[key] === 'string' &&
              obj[key].includes('@')
            ) {
              console.log(`Found potential email at ${path}.${key}: ${obj[key]}`);
            }
          }
        };

        checkNestedObject(currentUser.signInUserSession.idToken.payload);
      }
    }

    console.log("Final processed attributes:", attributes);

    // Extract user info from Cognito attributes with fallbacks
    const email = attributes.email ||
      currentUser.signInUserSession?.idToken?.payload?.email ||
      // Additional places to look
      currentUser.signInUserSession?.idToken?.payload?.['cognito:email'] ||
      currentUser.signInUserSession?.idToken?.payload?.['custom:email'] ||
      currentUser.email;

    console.log("Extracted email:", email);

    // Some providers capitalize attribute names differently
    const firstName = attributes.given_name ||
      attributes.givenName ||
      currentUser.signInUserSession?.idToken?.payload?.given_name ||
      (attributes.name ? attributes.name.split(' ')[0] : '');

    const lastName = attributes.family_name ||
      attributes.familyName ||
      currentUser.signInUserSession?.idToken?.payload?.family_name ||
      (attributes.name && attributes.name.includes(' ') ?
        attributes.name.split(' ').slice(1).join(' ') : '');

    // For Google sign-in, userId is the Cognito generated ID
    const userId = currentUser.username;

    console.log("Final extracted values:", {
      userId,
      email,
      firstName,
      lastName
    });

    // Safety check - if we still don't have the email
    if (!email || email === userId || !email.includes('@')) {
      console.error("Could not extract valid email from user attributes. Current extract:", email);
      console.error("Full currentUser object for debugging:", JSON.stringify(currentUser, null, 2));

      // Instead of creating a user with a debug email that won't be used,
      // just return an error to the user
      return {
        type: "error",
        error: new Error("Could not extract valid email from social login"),
        message: "We could not retrieve your email from your social account. Please try a different sign-in method or contact support."
      };
    }

    // Now call our createOrLinkSocialUser function
    const result = await createOrLinkSocialUser({
      userId,
      email,
      firstName: firstName || 'Social',
      lastName: lastName || 'User',
      cognitoUser: currentUser,
    });

    // Try to retrieve onboarding state from storage (using our utility)
    let onboardingState: any = null;
    let returnToOnboarding = false;

    // First try to get onboarding state from passed parameters
    if (storefrontSlug && appSlug) {
      console.log("Using passed storefront and app slugs:", { storefrontSlug, appSlug });
      returnToOnboarding = true;
    }
    // Then try to get from storage
    else {
      // Use our storage utility that handles fallbacks
      onboardingState = getStateFromStorage('onboardingAuthState');

      if (onboardingState) {
        console.log("Retrieved onboarding state from storage:", onboardingState);

        // Check if it's a valid onboarding state
        if (onboardingState.isOnboarding &&
          onboardingState.storefrontSlug &&
          onboardingState.appSlug) {
          returnToOnboarding = true;

          // Use the values from storage
          storefrontSlug = storefrontSlug || onboardingState.storefrontSlug;
          appSlug = appSlug || onboardingState.appSlug;

          console.log("Using onboarding data from storage:", { storefrontSlug, appSlug });
        }
      }
    }

    // If we have both storefrontSlug and appSlug, link the email with the onboarding
    if (result.type === "success" && storefrontSlug && appSlug) {
      try {
        // Import needed for linkOnboardingWithEmail
        const { linkOnboardingWithEmail } = await import('~/api/DXAppApi');

        console.log("Linking onboarding with email after social authentication");
        const linkResult = await linkOnboardingWithEmail(
          storefrontSlug,
          appSlug,
          email,
          result.value // Pass the authenticated user
        );

        console.log("Linking result after social auth:", linkResult);

        // Even if linking fails, we still return the user as authenticated
        // since the main authentication was successful
      } catch (linkError) {
        console.error("Error linking onboarding with email after social auth:", linkError);
        // We don't fail the whole authentication process if just the linking fails
      }
    }

    // Clear the onboarding state from storage if it exists
    removeStateFromStorage('onboardingAuthState');

    // Include the onboarding information in the result for redirect handling
    if (result.type === "success" && returnToOnboarding) {
      return {
        type: "success",
        value: result.value,
        metadata: {
          returnToOnboarding: true,
          storefrontSlug,
          appSlug,
          returnPath: onboardingState?.returnPath || `/apps/${storefrontSlug}/${appSlug}/onboarding`
        }
      };
    }

    return result;
  } catch (error) {
    console.error("Error handling social auth redirect:", error);
    Sentry.captureException(error);
    return {
      type: "error",
      error: error as Error,
      message: "Failed to log in. It's likely you have an old account with the same email. Please log in with your old credentials or contact support."
    };
  }
};

// New function to create or link a social user
export const createOrLinkSocialUser = async ({
  userId,
  email,
  firstName,
  lastName,
  cognitoUser
}: {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  cognitoUser: any;
}): Promise<Result<StudioUser>> => {
  try {
    console.log("Creating or linking social user:", { userId, email, firstName, lastName });

    // Extract provider information if available
    let provider = "unknown";
    if (cognitoUser.signInUserSession?.idToken?.payload?.identities) {
      const identities = cognitoUser.signInUserSession.idToken.payload.identities;
      if (Array.isArray(identities) && identities.length > 0) {
        provider = identities[0].providerType || identities[0].providerName || "unknown";
      }
    }

    // Call the DX API endpoint (JSON-based) for social sign-in
    console.log("Creating/linking user with DX API endpoint...");
    const userData = {
      userId,
      email,
      firstName,
      lastName,
      isSocialSignIn: true,
      socialProvider: provider
    };

    const createUserResult = await fetchDxApiAuthenticated({
      path: "/users/createUser",
      method: "POST",
      body: JSON.stringify(userData),
      isJsonPayload: true
    });

    console.log("DX API create/link user result:", createUserResult);

    if (!(createUserResult.status === ApiStatus.SUCCESS)) {
      console.error("Failed ?? to create or link user in backend:", createUserResult);
      return {
        type: "error",
        error: new Error("Failed to create or link user in backend"),
        message: createUserResult.message || "Failed to complete social authentication. Please try again."
      };
    }

    // Get the user information after successful creation/linking
    const getUserResult = await fetchAuthenticatedUser();
    console.log("getUserResult after creation/linking:", getUserResult);

    if (getUserResult.type === "success") {
      return { type: "success", value: getUserResult.value };
    } else {
      Sentry.captureException(getUserResult.error);
      return {
        type: "error",
        error: getUserResult.error,
        message: "It's likely you have an old account with the same email. Please log in with your old credentials or contact support."
      };
    }
  } catch (error) {
    console.error("Error creating or linking social user:", error);
    Sentry.captureException(error);
    return {
      type: "error",
      error: error as Error,
      message: "Failed to create or link your social account. Please try again."
    };
  }
};

export const checkUserExists = async (email: string): Promise<{ exists: boolean; email: string }> => {
  try {
    const response = await fetchDxApi({
      path: `/users/checkUserExists?email=${encodeURIComponent(email)}`,
      method: "GET",
    });

    return response.response;
  } catch (error) {
    console.error('Error checking user existence:', error);
    throw error;
  }
};
