import { useRouter } from "next/router";

import { CollectionData } from "data/collections";
import { ProductData, PurchaseableItem } from "data/product/products";

import {
  BTS_PRODUCT_VIDEO_PAGE_GET_STARTED,
  CHALLENGE_PAGE_GET_STARTED,
  COLLECTIONS_PAGE_GET_STARTED,
  CREATORS_PAGE_GET_STARTED,
  CREATOR_DASHBOARD_PAGE_GET_STARTED,
  CREATOR_STUDIO_PAGE_GET_STARTED,
  HOME_PAGE_GET_STARTED,
  MUSIC_PAGE_GET_STARTED,
  PRODUCT_PAGE_GET_STARTED,
  SCHOOLS_PAGE_GET_STARTED,
  DX_APP_LANDING_PAGE_VISIT
} from "~/analytics/Amplitude/amplitudeConstants";
import { addUTMPropertiesToEvent } from "~/analytics/Amplitude/amplitudeUtils";
import { useAnalytics } from "~/analytics/useAnalytics";
import { MembershipStatus } from "~/api/membershipApi";
import { AuthModalInteractionSource } from "~/hooks/auth/AuthProvider";
import { useAuth } from "~/hooks/auth/useAuth";
import { useAllAuthModalFunctions } from "~/hooks/auth/useAuthModal";
import { useCart } from "~/hooks/cart/useCart";
import { getProductCheckoutPath } from "~/util/stringUtils";

import { AuthForm } from "../auth/authTypes";
import { useMembershipInfo } from "./hooks/useMembershipInfo";
import { product } from "../videoProductPage/Product.css";

export enum LandingPageSources {
  Music,
  Home,
  Challenge,
  Storefront,
  BTSVideo,
  Product,
  Creators,
  Dashboard,
  Collections,
  Schools,
  Apps
}

export const useProductLandingPage = (
  source: LandingPageSources,
  productData: PurchaseableItem,
) => {
  const { trackAmplitudeEvent } = useAnalytics();
  const router = useRouter();
  const { user } = useAuth();
  const { addToCart, removeFromCart, isItemInCart, addToCartAndRemoveAnythingElse } = useCart();
  const { presentAuthModal } = useAllAuthModalFunctions();
  const { membershipInfo } = useMembershipInfo();

  const trackingCodeBySource: { [key in LandingPageSources]: string } = {
    [LandingPageSources.Home]: HOME_PAGE_GET_STARTED,
    [LandingPageSources.Challenge]: CHALLENGE_PAGE_GET_STARTED,
    [LandingPageSources.Music]: MUSIC_PAGE_GET_STARTED,
    [LandingPageSources.Storefront]: CREATOR_STUDIO_PAGE_GET_STARTED,
    [LandingPageSources.BTSVideo]: BTS_PRODUCT_VIDEO_PAGE_GET_STARTED,
    [LandingPageSources.Product]: PRODUCT_PAGE_GET_STARTED,
    [LandingPageSources.Creators]: CREATORS_PAGE_GET_STARTED,
    [LandingPageSources.Dashboard]: CREATOR_DASHBOARD_PAGE_GET_STARTED,
    [LandingPageSources.Collections]: COLLECTIONS_PAGE_GET_STARTED,
    [LandingPageSources.Schools]: SCHOOLS_PAGE_GET_STARTED,
    [LandingPageSources.Apps]: DX_APP_LANDING_PAGE_VISIT
  };

  const isSubscriber =
    user?.membershipStatus === MembershipStatus.active ||
    membershipInfo?.status === MembershipStatus.active;

  const ctaText = isSubscriber ? "Go to Dashboard" : "Explore Classes";

  const analyticsData = {
    pageSource: router.pathname,
    interactionSource: AuthModalInteractionSource.HomepageCTA,
  };
  const openAuthFlowByCampaign = () => presentAuthModal(AuthForm.Email, analyticsData);

  const onPursuitFinderClick = () => {
    if (!user) {
      openAuthFlowByCampaign();
    } else {
      isSubscriber ? router.push("/v2/dashboard") : presentAuthModal(AuthForm.Email, analyticsData);
    }
  };

  const onCtaClick = () => {
    trackAmplitudeEvent(trackingCodeBySource[source], addUTMPropertiesToEvent());

    console.log("ADDING ITEM TO CART ? ", productData, isItemInCart(productData));

    if (productData.subscriptionSlug && productData.subscriptionSlug.length > 0) {
      console.log("in here subscription one ? so should remove cart items ")
      addToCartAndRemoveAnythingElse(productData);
    } else {
      addToCart(productData);
    }
    //if (!isItemInCart(productData)) {

    //}

    const queryParams = router.query;
    // Convert query parameters to a string
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    const checkoutPathWithQuery = `/checkout?${queryString}`;
    if (user) {
      //const checkoutPath = getProductCheckoutPath(productData);
      const checkoutPath = checkoutPathWithQuery;
      return router.push(checkoutPath);
    } else {
      //router.query.redirect = getProductCheckoutPath(productData);
      router.query.redirect = checkoutPathWithQuery;
      router.push(router, undefined, { scroll: false });
      presentAuthModal(AuthForm.Email, {
        pageSource: router.pathname,
        interactionSource: AuthModalInteractionSource.HomepageCTA,
      });
    }

    if (source == LandingPageSources.Product) {
      console.log("ON CTA CLICK ON PRODUCT PAGE:  ", source);
    } else {
      console.log(" ON CTA CLICK WHO ARE YOU:  ", source);
    }
  };

  return {
    onCtaClick,
    ctaText,
    openAuthFlowByCampaign,
    onPursuitFinderClick,
    isSubscriber,
  };
};

/*

Old useLandingPage for old class landing pages

*/

export const useLandingPage = (source: LandingPageSources) => {
  const { trackAmplitudeEvent } = useAnalytics();
  const router = useRouter();

  const { user } = useAuth();
  const { presentAuthModal } = useAllAuthModalFunctions();
  const { membershipInfo } = useMembershipInfo();

  const trackingCodeBySource: { [key in LandingPageSources]: string } = {
    [LandingPageSources.Home]: HOME_PAGE_GET_STARTED,
    [LandingPageSources.Challenge]: CHALLENGE_PAGE_GET_STARTED,
    [LandingPageSources.Music]: MUSIC_PAGE_GET_STARTED,
    [LandingPageSources.Storefront]: CREATOR_STUDIO_PAGE_GET_STARTED,
    [LandingPageSources.BTSVideo]: BTS_PRODUCT_VIDEO_PAGE_GET_STARTED,
    [LandingPageSources.Product]: PRODUCT_PAGE_GET_STARTED,
    [LandingPageSources.Creators]: CREATORS_PAGE_GET_STARTED,
    [LandingPageSources.Dashboard]: CREATOR_DASHBOARD_PAGE_GET_STARTED,
    [LandingPageSources.Collections]: COLLECTIONS_PAGE_GET_STARTED,
    [LandingPageSources.Schools]: SCHOOLS_PAGE_GET_STARTED,
    [LandingPageSources.Apps]: DX_APP_LANDING_PAGE_VISIT
  };

  const isSubscriber =
    user?.membershipStatus === MembershipStatus.active ||
    membershipInfo?.status === MembershipStatus.active;

  const ctaText = isSubscriber ? "Go to Dashboard" : "Explore Classes";

  const analyticsData = {
    pageSource: router.pathname,
    interactionSource: AuthModalInteractionSource.HomepageCTA,
  };
  const openAuthFlowByCampaign = () => presentAuthModal(AuthForm.Email, analyticsData);

  const onPursuitFinderClick = () => {
    if (!user) {
      openAuthFlowByCampaign();
    } else {
      isSubscriber ? router.push("/v2/dashboard") : presentAuthModal(AuthForm.Email, analyticsData);
    }
  };

  const onCtaClick = () => {
    trackAmplitudeEvent(trackingCodeBySource[source], addUTMPropertiesToEvent());
    if (isSubscriber) {
      return router.push("/v2/dashboard");
    }

    console.log("ON CTA CLICK:  ", source);
    if (source == LandingPageSources.Home) {
      // When there's experiences enrolling, use this
      //const element = document.getElementById("currently-enrolling-experiences-section");

      // When there are no experiences enrolling, temporarily use this
      const element = document.getElementById("verticals-preview-section-container");
      const headerOffset = 120;
      const elementPosition = element?.getBoundingClientRect().top;
      const offsetPosition = elementPosition
        ? elementPosition + window.pageYOffset - headerOffset
        : 0;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      return;
    } else {
      console.log(" ON CTA CLICK WHO ARE YOU:  ", source);
    }

    //if (user) {
    // Since we only have 1 tier, direct to checkout of that tier. If we go back to multiple tiers, can direct to /checkout for tier picker page.
    //return router.push("/v2/checkout/standard-v1");
  };

  // TODO: update this - valentin
  // All other non-logged in users
  // presentAuthModal(AuthForm.Email, {
  //   pageSource: router.pathname,
  //   interactionSource: AuthModalInteractionSource.HomepageCTA,
  // });
  //};

  return {
    onCtaClick,
    ctaText,
    openAuthFlowByCampaign,
    onPursuitFinderClick,
    isSubscriber,
  };
};
